import React, { Component } from 'react';

class LearningLink extends Component {
  render() {
    return (
      <div>
        <p>
          Go to our <a href="/learning-center">Learning Center</a> to learn more about stock market performance and how it affects you.</p>                
      </div>
    );
  }
}

export default LearningLink;

import React, { Component } from "react";
import { graphql } from "gatsby";
import moment from "moment"; 

// Common components & Layout
import Layout from "../layouts";
import PresidentPerformance from "../components/performance/president";
import SEO from "../components/common/SEO"; 
import {lastnameImage} from "../utils/formatters"; 
import { presidentSlug } from "../utils/slugs"; 

class PresidentPage extends Component {
  render() {

    const president = this.props.data.presidentSummary;

    let lastUpdated; 
    for(let item of this.props.data.allLatestMetrics.edges) {
       if(item.node.name === 'dow') {
        lastUpdated = moment(item.node.date).utcOffset(6).format('MMMM Do, YYYY');        
       }
    }  

    return (
      <Layout>
        <SEO post={{
          title: `${president.name} Performance on Stock Market, GDP and Jobs Created`,
          description: `See how the stock market, gross domestic product (GDP), and jobs have changed under the ${president.name} administration`,
          imageLink: `${lastnameImage(president.name)}-official.jpg`,
          path: `/presidents/${presidentSlug(president.name)}/`
        }} />

        <PresidentPerformance
          location={this.props.location.href || ''} 
          party={president.party}
          name={president.name}
          date={president.term}
          title={president.name}
          presidentData={president}
          lastUpdated={lastUpdated}
        />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query($id: String!) {
    presidentSummary(id: { eq: $id }) {
      id
      dataSummary
      gdpSummary
      jobSummary
      keyEvents
      name
      keyPolicies
      party
      stockSummary
      summary
      term
      presidentId
      dow_1_perc
      dow_2_perc
      sandp_2_perc
      sandp_1_perc
      payroll_1_perc
      payroll_2_perc
      gdp_1_perc
      gdp_2_perc
      nasdaq_1_perc
      nasdaq_2_perc
      dow_today_percent
      dow_total
      dow_annualized
      sandp_today_percent
      sandp_total
      sandp_annualized
      payroll_total
      payroll_annualized
      nasdaq_today_percent
      nasdaq_total
      nasdaq_annualized
      gdp_total
      gdp_annualized
    }
    allLatestMetrics {
      edges {
        node {
          id
          date
          name
          todayTotalGain
          percentGain
        }
      }
    }
  }
`;

export default PresidentPage;

import React, { Component } from "react"
import { Table } from "react-bootstrap";
import Newsletter from "../../components/newsletter/newsletter"
import { Row, Col } from "react-bootstrap";
import LearningLink from "../common/LearningLink"; 
import Section from "../../components/common/Section"; 
import SocialMediaLink from "../../components/common/SocialMediaLink"; 

/* Library Functional Support */
import { percent, lastnameImage } from "../../utils/formatters"; 
import { presidentSlug } from "../../utils/slugs"; 
import parse from "html-react-parser"; 

/* Custom CSS */
import "./performance.module.css";
import Linechart from "../common/Linechart";

function getTheme(party) {
  let partyName = party.toLowerCase(); 
  switch(partyName) {  
    case 'republican':
      return 'red portrait';      
    case 'democrat':
      return 'blue portrait';      
    default: 
      return 'orange portrait';       
  }
} 

function gainedOrLost(number) {
  if(number < 0) {
    return `lost ${percent(-number)}`;
  } else {
    return `gained ${percent(number)}`; 
  }
}

function grewOrShrunk(number) {
  if(number < 0) {
    return `shrunk ${percent(-number)}`;
  } else {
    return `grew ${percent(number)}`; 
  }
}

function growthOrLoss(number) {
  if(number < 0) {
    return `loss of ${percent(-number)}`;
  } else {
    return `groth of ${percent(number)}`; 
  }  
}

function getClassName(number) {
  if(!number) {
    return '';
  }

  if(number < 0) {
    return 'negative';
  } 

  return 'positive'; 
}

class PresidentPerformance extends Component {

    constructor(props) {
      super(props);
      
      this.chartStocks = []; 
      this.chartGDP = []; 
      this.chartPayroll = []; 

      this.state = {
        isLoaded: false,
        chartStocks: [],
        chartGDP: [],
        chartPayroll: []
      };
    }

    componentDidMount() {
      if(!this.state.isLoaded) {
        const pres = this.props.presidentData.presidentId;                 
        let stock1 = require(`../../../static/data/president-${pres}-2.json`);   
        let stock2 = require(`../../../static/data/president-${pres}-3.json`);   
        let stock3 = require(`../../../static/data/president-${pres}-4.json`);                   
        const chartGDP = require(`../../../static/data/president-${pres}-1.json`);
        const chartPayroll = require(`../../../static/data/president-${pres}-17.json`);  

        stock1[0].id = 'NASDAQ'; 
        stock2[0].id = 'DOW JONES';
        stock3[0].id = 'S&P 500';       
        chartGDP[0].id = 'Gross Domestic Product'; 
        chartPayroll[0].id = 'Non-Farm Payroll Jobs';

        this.setState({ 
          isLoaded: true,
          chartStocks: stock1.concat(stock2).concat(stock3),
          chartGDP: chartGDP,
          chartPayroll: chartPayroll
        }); 

        console.log(this.state.chartStocks); 
      }      
    }
    
    render() {
        const president = this.props.presidentData;         
        const shareStockText = `See how the stock market, gross domestic product (GDP), and jobs have changed under the ${president.name} administration`;

        return (

        <div>
          <header />          
          
          <div id="hero" className={getTheme(this.props.party)}>
            <div className={`hero-text left portrait ${lastnameImage(this.props.name)}`}>
              <h2>{this.props.title}</h2>
              <cite>{this.props.date}</cite>
            </div>
          </div>
          
          <main id="performance" className="single-president">
            <section id="intro">
            <p style={{fontStyle:'italic'}}>Last updated: {this.props.lastUpdated}</p>
              <div className="container">                
                { parse(president.summary) }
              </div>
            </section>
            <section id="summary">
              <div className="section-hed">Summary of {this.props.name}’s performance</div>
              <div className="container">
                <p> Below is a table summarizing { this.props.name }'s cumulative and annualized performance across all the metrics we track. These are for the entirety of { this.props.name }'s term. </p>            
                <div className="chart">
                  <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Indicator</th>
                      <th>Cumulative Gain/Loss</th>                                    
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>NASDAQ</td>
                      <td className={getClassName(president.nasdaq_total)}>
                        {percent(president.nasdaq_total)}
                      </td>                    
                    </tr>
                    <tr>
                      <td>DOW</td>
                      <td className={getClassName(president.dow_total)}>{percent(president.dow_total)}</td>                    
                    </tr>
                    <tr>
                      <td>S&P</td>
                      <td className={getClassName(president.sandp_total)}>{percent(president.sandp_total)}</td>                    
                    </tr>
                    <tr>
                      <td>Job Growth</td>
                      <td className={getClassName(president.payroll_total)}>{percent(president.payroll_total)}</td>                    
                    </tr>
                    <tr>
                      <td>GDP Growth</td>
                      <td className={getClassName(president.gdp_total)}>{percent(president.gdp_total)}</td>                    
                    </tr>
                  </tbody>
                </Table>  
                <br />                
                <SocialMediaLink text={shareStockText} url={`presidents/${presidentSlug(president.name)}`} />    
              </div>                

                <div className="key-event">Key Policies</div>  
                  { parse('<ul><li>' + president.keyPolicies.replace(/\);/g, ')</li><li>') + '</li></ul>') }              
                <div className="key-event">Key Events</div>                
                  { parse('<ul><li>' + president.keyEvents.replace(/\);/g, ')</li><li>') + '</li></ul>') }                

              </div>
            </section>
            <section id="stock-market-performance">
              <div className="section-hed">{president.name}'s stock market performance</div>
              <div className="container">
              <p> Under {this.props.name}’s presidency, the S&P 500 { gainedOrLost(president.sandp_total) }, the NASDAQ { gainedOrLost(president.nasdaq_total) } and the Dow Jones { gainedOrLost(president.dow_total) }. 
              Annualized, the S&P 500 { gainedOrLost(president.sandp_annualized)}, the NASDAQ { gainedOrLost(president.nasdaq_annualized) } and the Dow Jones { gainedOrLost(president.dow_annualized) }              
              </p>                       
              <p>
              <b>Here is {this.props.name}’s cumulative stock market performance broken out by term.</b>
              </p>
                <div className="chart">

                <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Indicator</th>
                    <th>Cumulative End of Term 1</th>
                    <th>Cumulative End of Term 2</th>                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NASDAQ</td>
                    <td>{percent(president.nasdaq_1_perc)}</td>
                    <td>{percent(president.nasdaq_2_perc)}</td>                    
                  </tr>
                  <tr>
                    <td>DOW</td>
                    <td>{percent(president.dow_1_perc)}</td>
                    <td>{percent(president.dow_2_perc)}</td>                    
                  </tr>
                  <tr>
                    <td>S&P</td>
                    <td>{percent(president.sandp_1_perc)}</td>
                    <td>{percent(president.sandp_2_perc)}</td>
                  </tr>
                </tbody>
              </Table>                                                      
              <br />
              <SocialMediaLink text={shareStockText} url={`presidents/${presidentSlug(president.name)}`} />    
                </div>                     
                <p>
                </p>
                <div className="chart">
                  <Linechart 
                    chartData={this.state.chartStocks} 
                    title="Stock Performance Chart" 
                    yScaleMin="auto"                    
                    xLabel="Day"
                    yLabel="Index"
                   />  
                </div>
                <LearningLink />
              </div>
             <Row><Col><Newsletter /></Col></Row>             
            </section>
            <section id="gdp-growth-performance">
              <div className="section-hed">{president.name.split(" ").pop()} GDP growth performance</div>
              <div className="container">
              During {this.props.name}’s presidency, the GDP { gainedOrLost(president.gdp_total) }. That translates annually as GDP { gainedOrLost(president.gdp_annualized)}.
                <div className="chart">
                  <br />
                <p><b>Here is {president.name.split(" ").pop()}’s cumulative GDP growth performance broken out by term.</b></p>
                <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Indicator</th>
                    <th>Cumulative End of Term 1</th>                                    
                    <th>Cumulative End of Term 2</th>                                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>GDP Growth</td>
                    <td>{percent(president.gdp_1_perc)}</td>                    
                    <td>{percent(president.gdp_2_perc)}</td>                    
                  </tr>                  
                </tbody>
              </Table>   
              <br />
              <SocialMediaLink text={shareStockText} url={`presidents/${presidentSlug(president.name)}`} />    
                <Linechart 
                    chartData={this.state.chartGDP} 
                    title="GDP Performance Chart" 
                    yScaleMin="auto"                    
                    xLabel="Quarter"
                    yLabel="Gain/Loss"
                    xAxis="quarterly"
                   />
                </div>               
              </div>
            </section>
            <br/>
            <section id="job-growth-performance">
              <div className="section-hed">{president.name.split(" ").pop()} jobs growth performance</div>
              <div className="container">
              During {this.props.name}’s presidency, payrolls { grewOrShrunk(president.payroll_total)} cumulatively. That translated to an annualized jobs {growthOrLoss(president.payroll_annualized) }.<br />
              <p><b>Here is {president.name.split(" ").pop()}’s cumulative job growth performance broken out by term.</b></p>                    
                <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Indicator</th>
                    <th>Cumulative End of Term 1</th>                                    
                    <th>Cumulative End of Term 2</th>                                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Job Growth</td>
                    <td>{percent(president.payroll_1_perc)}</td>                    
                    <td>{percent(president.payroll_2_perc)}</td>                    
                  </tr>                  
                </tbody>
              </Table>                   
              <br />
              <SocialMediaLink text={shareStockText} url={`presidents/${presidentSlug(president.name)}`} />    
                <Linechart 
                    chartData={this.state.chartPayroll}
                    title="Job Growth Performance Chart" 
                    yScaleMin="auto"                    
                    xLabel="Month"
                    yLabel="Gain/Loss"
                    xAxis="monthly"
                   />                
              </div>
            </section>
            <section id="calculate">
              <Section
                name="metrics_calculation"
                header={`How we calculate President ${president.name.split(" ").pop()}'s data for Facts First?`}
              />
             <Row><Col><Newsletter /></Col></Row>			
            </section>
          </main>          
        </div>          
        )
    }

}

export default PresidentPerformance; 